//----------------------------------------------------------//
//	OPTIONS
//----------------------------------------------------------//
$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: true !default;
$enable-smooth-scroll: false !default;
$enable-negative-margins: true !default;
$variable-prefix:             bs- !default; // Deprecated in v5.2.0 for the shorter `$prefix`
$prefix:                      $variable-prefix !default;
//----------------------------------------------------------//
//	SPACING
//----------------------------------------------------------//
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * 0.25), // 5px
    2: ($spacer * 0.5), // 10px
    3: ($spacer * 0.75), // 15px
    4: $spacer, // 20px
    5: ($spacer * 1.25), // 25px
    6: ($spacer * 1.5), // 30px
    7: ($spacer * 1.75), // 35px
    8: ($spacer * 2), // 40px
    9: ($spacer * 2.25), // 45px
    10: ($spacer * 2.5), // 50px
    11: ($spacer * 3), // 60px
    12: ($spacer * 3.5), // 70x
    13: ($spacer * 4), // 80px
    14: ($spacer * 4.5), // 90px
    15: ($spacer * 5), // 100px
    16: ($spacer * 6), // 120px
    17: ($spacer * 7), // 140px
    18: ($spacer * 8), // 160px
    19: ($spacer * 9), // 180px
    20: ($spacer * 10), // 200px
    21: ($spacer * 12.5), // 250px
    22: ($spacer * 15), // 300px
    23: ($spacer * 17.5), // 350px
    24: ($spacer * 20), // 400px
    25: ($spacer * 22.5), // 450px
  ),
  $spacers
);
$negative-spacers: if($enable-negative-margins, negativify-map($spacers), null) !default;
//----------------------------------------------------------//
//	COLOR SYSTEM
//----------------------------------------------------------//
$white: #fff !default;
$black: #000 !default;
$gray-100: #fefefe !default;
$gray-200: #f6f7f9 !default;
$gray-300: #cacaca !default;
$gray-400: #aab0bc !default;
$gray-500: #959ca9 !default;
$gray-600: #60697b !default;
$gray-700: #2f353a !default;
$gray-800: #21262c !default;
$gray-900: #1e2228 !default;
$error: #de4747 !default;
$light: $gray-100 !default;
$gray: $gray-200 !default;
$dark: #262b32 !default;
$inverse: $gray-300 !default;
$border: #a4aec6 !default;
$border-light: rgba($border, 0.2) !default;
$table-border: #edeff3 !default;
$box-layout-bg: #edeef1 !default;
$shadow-border: #083c82 !default;
$pre-color: #273444 !default;
$pre-bg: #273444 !default;
$lineal-stroke: #36496d !default;
$dropdown-dark-bg: #2e353e !default;
$feedback-success: #198754;
$feedback-danger: #dc3545;
$primary: $blue !default;
$colors: ("blue": $blue,
  "sky": $sky,
  "purple": $purple,
  "grape": $grape,
  "violet": $violet,
  "pink": $pink,
  "fuchsia": $fuchsia,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "leaf": $leaf,
  "aqua": $aqua,
  "navy": $navy,
  "ash": $ash,
  "white": $white,
  "light": $light,
  "gray": $gray,
  "dark": $dark,
  "primary": $primary,
) !default;
$secondary: $gray-400 !default;
$success: $green !default;
$info: $aqua !default;
$warning: $yellow !default;
$danger: $red !default;
$theme-colors: map-merge($colors,
  ("secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
  ));
$text-colors: map-remove($theme-colors, "dark") !default;
$link-colors: map-remove($theme-colors, "dark") !default;
$main-color: $primary !default;
$component-active-color: $white !default;
$component-active-bg: $main-color !default;
$main-dark: $navy !default;
$focus-border: tint-color($component-active-bg, 50%) !default;
$color-contrast-dark: $main-dark !default;
$color-contrast-light: $white !default;
$min-contrast-ratio: 3 !default;
$gradient-1: linear-gradient(120deg,#f857a6 10%,#ef3f6e 100%) !default;
$gradient-2: linear-gradient(40deg, rgba(245,177,97,1) 0.4%, rgba(236,54,110,1) 100.2%) !default;
$gradient-3: linear-gradient(45deg, #FBDA61 0%, #FF5ACD 100%) !default;
$gradient-4: linear-gradient(125deg, #9040db, #ff72c2 50%, #ffd84f) !default;
$gradient-5: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%) !default;
$gradient-6: linear-gradient(45deg, #08AEEA 0%, #2AF598 100%) !default;
$gradient-7: linear-gradient(100deg,#605dba 20%,#3f78e0 85%) !default;
$gradient-8: linear-gradient(0deg, #2c46a7, #3757c4) !default;
$gradient-9: linear-gradient(45deg, rgb(255, 222, 233), rgb(181, 255, 252)) !default;
$gradient-10: linear-gradient(100deg,#003da7, 50%,#011a5b 100%) !default;
$gradients: (
  gradient-1: $gradient-1,
  gradient-2: $gradient-2,
  gradient-3: $gradient-3,
  gradient-4: $gradient-4,
  gradient-5: $gradient-5,
  gradient-6: $gradient-6,
  gradient-7: $gradient-7,
  gradient-8: $gradient-8,
  gradient-9: $gradient-9,
  gradient-10: $gradient-10,
) !default;
$px: #00a6df !default;
$adobe: #e61f26 !default;
$android: #97c03d !default;
$amazon: #f79400 !default;
$apple: #1a1919 !default;
$behance: #0057ff !default;
$blackberry: #272727 !default;
$blogger: #e86733 !default;
$bitcoin: #f38633 !default;
$css3: #006db6 !default;
$dropbox: #007bdf !default;
$dribbble: #e94d88 !default;
$github: #4073a6 !default;
$google: #e44134 !default;
$googleplay: #24b6be !default;
$googledrive: #1b9f5e !default;
$googlehangouts: #109956 !default;
$facebook: #4470cf !default;
$facebookmessenger: #007ff8 !default;
$instagram: #d53581 !default;
$intercom: #2e88f8 !default;
$javascript: #f0d91d !default;
$line: #0fab10 !default;
$linkedin: #3393c1 !default;
$mastercard: #f0931d !default;
$medium: #00c364 !default;
$opera: #e33737 !default;
$paypal: #0089cc !default;
$react: #00d1f7 !default;
$reddit: #f84300 !default;
$slack: #d4135c !default;
$snapchat: #f7c31a !default;
$swiggy: #f88a2d !default;
$skype: #2ebbf0 !default;
$telegram: #2caef0 !default;
$tumblr: #5d82a4 !default;
$twitter: #5daed5 !default;
$youtube: #c8312b !default;
$visualstudio: #6b61be !default;
$vuejs: #3fb37f !default;
$vk: #4f7db3 !default;
$whatsapp: #00a859 !default;
$brand-colors: ("dropbox": $dropbox,
  "dribbble": $dribbble,
  "google-drive-alt": $googledrive,
  "facebook-f": $facebook,
  "instagram": $instagram,
  "line": $line,
  "facebook-messenger": $facebookmessenger,
  "black-berry": $blackberry,
  "linkedin": $linkedin,
  "intercom": $intercom,
  "facebook": $facebook,
  "youtube": $youtube,
  "apple": $apple,
  "visual-studio": $visualstudio,
  "twitter": $twitter,
  "snapchat-ghost": $snapchat,
  "slack": $slack,
  "tumblr-square": $tumblr,
  "medium-m": $medium,
  "tumblr": $tumblr,
  "adobe": $adobe,
  "whatsapp": $whatsapp,
  "500-px": $px,
  "snapchat-square": $snapchat,
  "behance": $behance,
  "instagram-alt": $instagram,
  "github-alt": $github,
  "linkedin-alt": $linkedin,
  "slack-alt": $slack,
  "google": $google,
  "google-play": $google,
  "google-drive": $googledrive,
  "vuejs-alt": $vuejs,
  "google-hangouts": $googlehangouts,
  "vuejs": $vuejs,
  "java-script": $javascript,
  "paypal": $paypal,
  "reddit-alien-alt": $reddit,
  "android-alt": $android,
  "github": $github,
  "android": $android,
  "amazon": $amazon,
  "adobe-alt": $adobe,
  "master-card": $mastercard,
  "opera-alt": $opera,
  "css3-alt": $css3,
  "opera": $opera,
  "css3": $css3,
  "twitter-alt": $twitter,
  "intercom-alt": $intercom,
  "swiggy": $swiggy,
  "snapchat-alt": $snapchat,
  "tumblr-alt": $tumblr,
  "facebook-messenger-alt": $facebookmessenger,
  "bitcoin-alt": $bitcoin,
  "google-hangouts-alt": $googlehangouts,
  "blogger": $blogger,
  "blogger-alt": $blogger,
  "bitcoin": $bitcoin,
  "behance-alt": $behance,
  "apple-alt": $apple,
  "react": $react,
  "telegram": $telegram,
  "vk-alt": $vk,
  "vk": $vk,
  "skype": $skype,
  "skype-alt": $skype,
  "telegram-alt": $telegram,
) !default;

//Text Emphasis
$primary-text-emphasis:   shade-color($primary, 30%) !default;
$secondary-text-emphasis: shade-color($secondary, 30%) !default;
$success-text-emphasis:   shade-color($success, 30%) !default;
$info-text-emphasis:      shade-color($info, 30%) !default;
$warning-text-emphasis:   shade-color($warning, 30%) !default;
$danger-text-emphasis:    shade-color($danger, 30%) !default;
$light-text-emphasis:     shade-color($light, 30%) !default;
$dark-text-emphasis:      shade-color($dark, 30%) !default;

//BG Subtle
$primary-bg-subtle:       tint-color($primary, 90.5%) !default;
$secondary-bg-subtle:     tint-color($secondary, 90.5%) !default;
$success-bg-subtle:       tint-color($success, 90.5%) !default;
$info-bg-subtle:          tint-color($info, 90.5%) !default;
$warning-bg-subtle:       tint-color($warning, 90%) !default; // yellow 
$danger-bg-subtle:        tint-color($danger, 90.5%) !default;
$light-bg-subtle:         mix($gray-200, $white) !default;
$dark-bg-subtle:          tint-color($dark, 90.5%) !default;
$primary-subtle:          tint-color($primary, 90.5%) !default;

//BG Soft
$primary-soft:       tint-color($primary, 90.5%) !default;
$blue-soft:          tint-color($blue, 90.5%) !default;
$sky-soft:           tint-color($sky, 90.5%) !default;
$purple-soft:        tint-color($purple, 90.5%) !default;
$grape-soft:         tint-color($grape, 90.5%) !default;
$violet-soft:        tint-color($violet, 90.5%) !default;
$pink-soft:          tint-color($pink, 90.5%) !default;
$fuchsia-soft:       tint-color($fuchsia, 90.5%) !default;
$red-soft:           tint-color($red, 90.5%) !default;
$orange-soft:        tint-color($orange, 90.5%) !default;
$yellow-soft:        tint-color($yellow, 90%) !default; // yellow 
$green-soft:         tint-color($green, 90.5%) !default;
$leaf-soft:          tint-color($leaf, 90.5%) !default;
$aqua-soft:          tint-color($aqua, 90.5%) !default;
$navy-soft:          tint-color($navy, 90.5%) !default;
$ash-soft:           tint-color($ash, 90.5%) !default;
$gray-soft:          $border-light !default;

//BG Pale
$primary-pale:         tint-color($primary, 84%) !default;
$blue-pale:            tint-color($blue, 84%) !default;
$sky-pale:             tint-color($sky, 84%) !default;
$purple-pale:          tint-color($purple, 84%) !default;
$grape-pale:           tint-color($grape, 84%) !default;
$violet-pale:          tint-color($violet, 84%) !default;
$pink-pale:            tint-color($pink, 84%) !default;
$fuchsia-pale:         tint-color($fuchsia, 84%) !default;
$red-pale:             tint-color($red, 84%) !default;
$orange-pale:          tint-color($orange, 84%) !default;
$yellow-pale:          tint-color($yellow, 84%) !default;
$green-pale:           tint-color($green, 84%) !default;
$leaf-pale:            tint-color($leaf, 84%) !default;
$aqua-pale:            tint-color($aqua, 84%) !default;
$navy-pale:            tint-color($navy, 84%) !default;
$dark-pale:            tint-color($dark, 84%) !default;
$ash-pale:             $border-light !default;

//Bullet Soft
$primary-bullet-soft:         tint-color($primary, 82%) !default;
$blue-bullet-soft:            tint-color($blue, 82%) !default;
$sky-bullet-soft:             tint-color($sky, 82%) !default;
$purple-bullet-soft:          tint-color($purple, 82%) !default;
$grape-bullet-soft:           tint-color($grape, 82%) !default;
$violet-bullet-soft:          tint-color($violet, 82%) !default;
$pink-bullet-soft:            tint-color($pink, 82%) !default;
$fuchsia-bullet-soft:         tint-color($fuchsia, 82%) !default;
$red-bullet-soft:             tint-color($red, 82%) !default;
$orange-bullet-soft:          tint-color($orange, 82%) !default;
$yellow-bullet-soft:          tint-color($yellow, 77%) !default; // yellow 
$green-bullet-soft:           tint-color($green, 82%) !default;
$leaf-bullet-soft:            tint-color($leaf, 82%) !default;
$aqua-bullet-soft:            tint-color($aqua, 82%) !default;
$navy-bullet-soft:            tint-color($navy, 82%) !default;
$ash-bullet-soft:             tint-color($ash, 82%) !default;

//Icon Fill
$primary-icon-fill:         tint-color($primary, 40%) !default;
$blue-icon-fill:            tint-color($blue, 40%) !default;
$sky-icon-fill:             tint-color($sky, 40%) !default;
$purple-icon-fill:          tint-color($purple, 40%) !default;
$grape-icon-fill:           tint-color($grape, 40%) !default;
$violet-icon-fill:          tint-color($violet, 40%) !default;
$pink-icon-fill:            tint-color($pink, 40%) !default;
$fuchsia-icon-fill:         tint-color($fuchsia, 40%) !default;
$red-icon-fill:             tint-color($red, 40%) !default;
$orange-icon-fill:          tint-color($orange, 40%) !default;
$yellow-icon-fill:          tint-color($yellow, 40%) !default;
$green-icon-fill:           tint-color($green, 40%) !default;
$leaf-icon-fill:            tint-color($leaf, 40%) !default;
$aqua-icon-fill:            tint-color($aqua, 40%) !default;
$navy-icon-fill:            tint-color($navy, 40%) !default;
$ash-icon-fill:             tint-color($ash, 40%) !default;

//Icon Solid Fill
$primary-icon-solid-fill:         tint-color($primary, 60%) !default;
$blue-icon-solid-fill:            tint-color($blue, 60%) !default;
$sky-icon-solid-fill:             tint-color($sky, 60%) !default;
$purple-icon-solid-fill:          tint-color($purple, 60%) !default;
$grape-icon-solid-fill:           tint-color($grape, 60%) !default;
$violet-icon-solid-fill:          tint-color($violet, 60%) !default;
$pink-icon-solid-fill:            tint-color($pink, 60%) !default;
$fuchsia-icon-solid-fill:         tint-color($fuchsia, 60%) !default;
$red-icon-solid-fill:             tint-color($red, 60%) !default;
$orange-icon-solid-fill:          tint-color($orange, 60%) !default;
$yellow-icon-solid-fill:          tint-color($yellow, 60%) !default;
$green-icon-solid-fill:           tint-color($green, 60%) !default;
$leaf-icon-solid-fill:            tint-color($leaf, 60%) !default;
$aqua-icon-solid-fill:            tint-color($aqua, 60%) !default;
$navy-icon-solid-fill:            tint-color($navy, 60%) !default;
$ash-icon-solid-fill:             tint-color($ash, 60%) !default;

//Border Subtle
$primary-border-subtle:   tint-color($primary, 70%) !default;
$secondary-border-subtle: tint-color($secondary, 70%) !default;
$success-border-subtle:   tint-color($success, 70%) !default;
$info-border-subtle:      tint-color($info, 70%) !default;
$warning-border-subtle:   tint-color($warning, 70%) !default;
$danger-border-subtle:    tint-color($danger, 70%) !default;
$light-text-emphasis:     mix($gray-300, $white) !default;
$dark-border-subtle:      tint-color($dark, 70%) !default;

//Border Soft
$primary-border-soft:   tint-color($primary, 70%) !default;
$blue-border-soft:      tint-color($blue, 70%) !default;
$sky-border-soft:       tint-color($sky, 70%) !default;
$purple-border-soft:    tint-color($purple, 70%) !default;
$grape-border-soft:     tint-color($grape, 70%) !default;
$violet-border-soft:    tint-color($violet, 70%) !default;
$pink-border-soft:      tint-color($pink, 70%) !default;
$fuchsia-border-soft:   tint-color($fuchsia, 70%) !default;
$red-border-soft:       tint-color($red, 70%) !default;
$orange-border-soft:    tint-color($orange, 70%) !default;
$yellow-border-soft:    tint-color($yellow, 70%) !default;
$green-border-soft:     tint-color($green, 70%) !default;
$leaf-border-soft:      tint-color($leaf, 70%) !default;
$aqua-border-soft:      tint-color($aqua, 70%) !default;
$navy-border-soft:      tint-color($navy, 70%) !default;
$ash-border-soft:       tint-color($ash, 70%) !default;
//----------------------------------------------------------//
//	BODY
//----------------------------------------------------------//
$body-bg: $gray-100 !default;
$body-color: $gray-600 !default;
//----------------------------------------------------------//
//	LINKS
//----------------------------------------------------------//
$link-color: $main-color !default;
$link-decoration: none !default;
$link-hover-color: $main-color !default;
$link-hover-decoration: null !default;
$link-transition: all 0.2s ease-in-out !default;
$link-transition2: all 0.3s ease-in-out !default;
$dropdown-transition: all 0.25s ease-in-out !default;
$bezier-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1) !default;
$emphasized-link-hover-darken-percentage: 0 !default;
//----------------------------------------------------------//
//	GRID
//----------------------------------------------------------//
$gutters: $spacers !default;
$grid-row-columns: 12 !default;
$box-layout-width: 1550px !default;
//----------------------------------------------------------//
//	COMPONENTS
//----------------------------------------------------------//
$border-width: 1px !default;
$border-radius: 0.4rem !default;
$border-radius-sm: 0.2rem !default;
$border-radius-lg: $border-radius !default;
$border-radius-xl: 0.8rem !default;
$rounded-pill: 1.5rem !default;
$box-shadow: 0rem 0rem 1.25rem rgba($gray-900, 0.04) !default;
$box-shadow-sm: 0rem 0.25rem 0.75rem rgba($gray-900, 0.02) !default;
$box-shadow-sm-hover: 0rem 0.25rem 0.75rem rgba($gray-900, 0.05) !default;
$box-shadow-md: 0rem 0rem 1.25rem rgba($gray-900, 0.06) !default;
$box-shadow-lg: 0rem 0.25rem 1.75rem rgba($gray-900, 0.07) !default;
$box-shadow-xl: rgb(30 34 40 / 2%) 0px 2px 1px, rgb(30 34 40 / 2%) 0px 4px 2px, rgb(30 34 40 / 2%) 0px 8px 4px, rgb(30 34 40 / 2%) 0px 16px 8px, rgb(30 34 40 / 3%) 0px 32px 16px !default;
$box-shadow-with-border: 0 0 0 0.05rem rgba($shadow-border, 0.06), $box-shadow !default;
$btn-shadow: 0rem 0.25rem 0.75rem rgba($gray-900, 0.15) !default;
$btn-shadow-sm: 0rem 0.25rem 0.75rem rgba($gray-900, 0.05) !default;
$caret-width: 0.25rem !default;
$caret-vertical-align: -3px !default;
$caret-spacing: 0.05rem !default;
$caret-size: 0.85rem !default;
$caret-end-size: 0.8rem !default;
$mark-padding: 0.05rem 0.4rem 0.2rem !default;
//----------------------------------------------------------//
//	TYPOGRAPHY
//----------------------------------------------------------//
$path-to-fonts: 'https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;700' !default;
$font-family-sans-serif: "Manrope", sans-serif !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-custom-icons: "Custom" !default;
$font-family-unicons: "Unicons" !default;
$font-size-root: 20px !default;
$font-size-base: 0.8rem !default;
$font-size-sm: 0.7rem !default;
$font-size-lg: 1rem !default;
$font-weight-lighter: lighter !default;
$font-weight-light: 400 !default;
$font-weight-normal: 500 !default;
$font-weight-medium: 500 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;
$font-weight-base: $font-weight-normal !default;
$letter-spacing-xs: -0.035em !default;
$letter-spacing-sm: -0.03em !default;
$letter-spacing: -0.01rem !default;
$letter-spacing-lg: 0.02rem !default;
$letter-spacing-xl: 0.05rem !default;
$line-height-base: 1.7 !default;
$line-height-xxs: 1.05 !default;
$line-height-xs: 1.35 !default;
$line-height-sm: 1.5 !default;
$line-height-lg: 1.9 !default;
$h1-font-size: $font-size-base * 1.25 * 1.45 !default;
$h2-font-size: $font-size-base * 1.25 * 1.3 !default;
$h3-font-size: $font-size-base * 1.25 * 1.1 !default;
$h4-font-size: $font-size-base * 1.25 * 0.95 !default;
$h5-font-size: $font-size-base * 1.25 * 0.9 !default;
$h6-font-size: $font-size-base * 1.25 * 0.85 !default;
$h1-line-height: 1.3 !default;
$h2-line-height: 1.35 !default;
$h3-line-height: 1.4 !default;
$h4-line-height: 1.45 !default;
$h5-line-height: 1.5 !default;
$h6-line-height: 1.55 !default;
$headings-font-weight: 700 !default;
$headings-color: $main-dark !default;
$display-font-sizes: (1: 2.4rem,
  2: 2.2rem,
  3: 2rem,
  4: 1.8rem,
  5: 1.6rem,
  6: 1.4rem,
) !default;
$display-font-weight: 700 !default;
$lead-font-size: $font-size-base * 1.25 * 0.9 !default;
$lead-font-weight: 500 !default;
$small-font-size: 0.6rem !default;
$text-muted: $secondary !default;
$blockquote-margin-y: $spacer !default;
$blockquote-font-size: $font-size-base * 1.25 * 0.85 !default;
$blockquote-font-weight: $font-weight-base !default;
$blockquote-footer-color: $secondary !default;
$blockquote-footer-font-size: $small-font-size !default;
$blockquote-footer-weight: $font-weight-bold !default;
//----------------------------------------------------------//
//	HR
//----------------------------------------------------------//
$hr-margin-y: 4.5rem !default;
$hr-color: $border-light !default;
$hr-height: $border-width !default;
$hr-opacity: 1 !default;
//----------------------------------------------------------//
//	BUTTONS
//----------------------------------------------------------//
$input-btn-padding-y: 0.5rem !default;
$input-btn-padding-x: 1.2rem !default;
$input-btn-font-family: null !default;
$input-btn-font-size: $font-size-base !default;
$input-btn-line-height: $line-height-base !default;
$input-btn-focus-width: 0.25rem !default;
$input-btn-focus-color-opacity: 0.25 !default;
$input-btn-focus-color: rgba($component-active-bg, $input-btn-focus-color-opacity) !default;
$input-btn-focus-box-shadow: unset !default;
$input-btn-padding-y-sm: 0.4rem !default;
$input-btn-padding-x-sm: 1rem !default;
$input-btn-font-size-sm: 0.7rem !default;
$input-btn-padding-y-lg: 0.65rem !default;
$input-btn-padding-x-lg: 1.4rem !default;
$input-btn-font-size-lg: 0.85rem !default;
$input-btn-border-width: $border-width !default;
$btn-padding-y: $input-btn-padding-y !default;
$btn-padding-x: $input-btn-padding-x !default;
$btn-font-family: $input-btn-font-family !default;
$btn-font-size: $input-btn-font-size !default;
$btn-line-height: $input-btn-line-height !default;
$btn-white-space: nowrap !default;
$btn-padding-y-sm: $input-btn-padding-y-sm !default;
$btn-padding-x-sm: $input-btn-padding-x-sm !default;
$btn-font-size-sm: $input-btn-font-size-sm !default;
$btn-padding-y-lg: $input-btn-padding-y-lg !default;
$btn-padding-x-lg: $input-btn-padding-x-lg !default;
$btn-font-size-lg: $input-btn-font-size-lg !default;
$btn-border-width: 2px !default;
$btn-font-weight: $font-weight-bold !default;
$btn-focus-width: $input-btn-focus-width !default;
$btn-focus-box-shadow: $input-btn-focus-box-shadow !default;
$btn-disabled-opacity: 0.65 !default;
$btn-active-box-shadow: $btn-shadow !default;
$btn-border-radius: $border-radius !default;
$btn-border-radius-sm: $border-radius !default;
$btn-border-radius-lg: $border-radius-lg !default;
$btn-transition: $link-transition !default;
$btn-box-shadow: unset !default;
$btn-hover-bg-shade-amount: 0% !default;
$btn-hover-bg-tint-amount: 0% !default;
$btn-hover-border-shade-amount: 0% !default;
$btn-hover-border-tint-amount: 0% !default;
$btn-active-bg-shade-amount: 0% !default;
$btn-active-bg-tint-amount: 0% !default;
$btn-active-border-shade-amount: 0% !default;
$btn-active-border-tint-amount: 0% !default;
//----------------------------------------------------------//
//	FORMS
//----------------------------------------------------------//
$input-padding-y: 0.6rem !default;
$input-padding-x: 1rem !default;
$input-padding-y-sm: 0.4rem !default;
$input-padding-x-sm: 0.6rem !default;
$input-font-size: 0.75rem !default;
$input-font-weight: 500 !default;
$input-line-height: $input-btn-line-height !default;
$form-label-color: $gray-500 !default;
$input-color: $gray-600 !default;
$input-border-width: $input-btn-border-width !default;
$input-height-border: $input-border-width * 2 !default;
$input-height: add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false)) !default;
$input-height-sm: add($input-line-height * 1em, add($input-padding-y-sm * 2, $input-height-border, false)) !default;
$input-border-color: rgba($shadow-border, 0.07) !default;
$input-box-shadow: $box-shadow !default;
$input-focus-border-color: $focus-border !default;
$input-focus-box-shadow: unset !default;
$input-placeholder-color: $gray-500 !default;
$input-border-radius: $border-radius !default;
$input-border-radius-sm: $input-border-radius !default;
$input-border-radius-lg: $input-border-radius !default;
$form-check-input-width: 1.05rem !default;
$form-check-padding-start: $form-check-input-width + .5rem !default;
$form-check-input-bg: $body-bg !default;
$form-check-input-border: 1px solid rgba($shadow-border, 0.1) !default;
$form-check-input-checked-color: $component-active-color !default;
$form-check-input-checked-bg-color: $component-active-bg !default;
$form-check-input-checked-border-color: $form-check-input-checked-bg-color !default;
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='1.5' fill='#{$form-check-input-checked-color}'/></svg>") !default;
$form-check-input-focus-border: rgba($shadow-border, 0.1) !default;
$form-check-input-focus-box-shadow: none !default;
$form-select-focus-border-color:  $form-check-input-focus-border !default;
$form-select-focus-box-shadow: unset !default;
$form-select-border-color: $input-border-color !default;
$form-select-border-radius: $border-radius !default;
$form-select-box-shadow: $box-shadow !default;
$form-select-font-size: $input-font-size !default;
$form-select-font-weight: $input-font-weight !default;
$form-select-color: $form-label-color !default;
$form-select-padding-y: $input-padding-y !default;
$form-select-padding-x: $input-padding-x !default;
$form-select-line-height: $input-line-height !default;
$form-select-bg-size: 20px 20px !default;
$form-select-indicator-color: $body-color !default;
$form-select-indicator: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cpath fill='#{$form-select-indicator-color}' d='M22.667 12.227c-0.241-0.24-0.573-0.388-0.94-0.388s-0.699 0.148-0.94 0.388l0-0-4.787 4.72-4.72-4.72c-0.241-0.24-0.573-0.388-0.94-0.388s-0.699 0.148-0.94 0.388l0-0c-0.244 0.242-0.394 0.577-0.394 0.947s0.151 0.705 0.394 0.947l5.653 5.653c0.242 0.244 0.577 0.394 0.947 0.394s0.705-0.151 0.947-0.394l0-0 5.72-5.653c0.244-0.242 0.394-0.577 0.394-0.947s-0.151-0.705-0.394-0.947l-0-0z'%3E%3C/path%3E%3C/svg%3E") !default;
$form-select-bg-position: right $form-select-padding-x - 0.25rem center !default;
$form-floating-height: add(2.5rem, $input-height-border) !default;
$form-floating-line-height: 1.25 !default;
$form-floating-padding-x: $input-padding-x !default;
$form-floating-padding-y: $input-padding-y !default;
$form-floating-input-padding-t: 1rem !default;
$form-floating-input-padding-b: 0.2rem !default;
$form-floating-label-opacity: 1 !default;
$form-floating-label-transform: scale(.8) translateY(-.4rem) translateX(.2rem) !default;
$form-feedback-valid-color: $feedback-success !default;
$form-feedback-invalid-color: $feedback-danger !default;
$form-feedback-icon-valid-color: $form-feedback-valid-color !default;
$form-feedback-font-size: $font-size-sm !default;
$input-height-inner-half: add($input-line-height * .25em, $input-padding-y) !default;
$form-feedback-icon-valid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='#{$form-feedback-icon-valid-color}' d='M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z'/%3E%3C/svg%3E") !default;
$form-select-indicator-padding: $form-select-padding-x * 2 !default;
$form-select-feedback-icon-position: center right $form-select-indicator-padding !default;
//----------------------------------------------------------//
//	NAVS
//----------------------------------------------------------//
$nav-link-padding-y: 1.2rem !default;
$nav-link-padding-x: 1rem !default;
$nav-link-font-size: 0.8rem !default;
$nav-link-font-weight: $font-weight-bold !default;
$nav-link-color: $main-dark !default;
$nav-link-hover-color: var(--#{$prefix}primary) !default;
$nav-link-transition: color 0.2s ease-in-out !default;
$nav-link-disabled-color: $gray-500 !default;
$nav-tabs-border-color: $border-light !default;
$nav-tabs-border-radius: $border-radius !default;
$nav-tabs-link-hover-border-color: transparent !default;
$nav-tabs-link-active-color: var(--#{$prefix}primary) !default;
$nav-tabs-link-active-bg: var(--#{$prefix}white) !default;
$nav-tabs-link-active-border-color: $border-light !default;
$nav-pills-border-radius: $border-radius !default;
$nav-pills-link-active-color: var(--#{$prefix}primary) !default;
$nav-pills-link-active-bg: var(--#{$prefix}white) !default;
//----------------------------------------------------------//
//	NAVBAR
//----------------------------------------------------------//
$navbar-padding-y: 0 !default;
$navbar-padding-x: 0 !default;
$navbar-nav-link-padding-x: 1rem !default;
$navbar-brand-font-size: $font-size-sm !default;
$nav-link-height: $font-size-base * $line-height-base+$nav-link-padding-y * 2 !default;
$navbar-brand-height: $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-padding-y: 0 !default;
$navbar-brand-margin-end: 0 !default;
$navbar-dark-color: var(--#{$prefix}white) !default;
$navbar-dark-hover-color: rgba(var(--#{$prefix}white-rgb), 0.7) !default;
$navbar-dark-active-color: rgba(var(--#{$prefix}white-rgb), 0.7) !default;
$navbar-dark-disabled-color: rgba(var(--#{$prefix}white-rgb), 0.25) !default;
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' width='45' height='32' viewBox='0 0 45 32'><path fill='#{$navbar-dark-color}' d='M26.88 29.888c-1.076 1.289-2.683 2.103-4.48 2.103s-3.404-0.814-4.472-2.093l-0.008-0.009-5.12-7.040-8.192-10.048-3.52-4.608c-0.646-0.848-1.036-1.922-1.036-3.087 0-2.828 2.292-5.12 5.12-5.12 0.139 0 0.277 0.006 0.413 0.016l-0.018-0.001h33.664c0.118-0.010 0.256-0.015 0.396-0.015 2.828 0 5.12 2.292 5.12 5.12 0 1.165-0.389 2.239-1.045 3.1l0.009-0.013-3.52 4.608-7.872 10.048z'/></svg>") !default;
$navbar-dark-toggler-border-color: transparent !default;
$navbar-light-color: $main-dark !default;
$navbar-light-hover-color: var(--#{$prefix}primary) !default;
$navbar-light-active-color: var(--#{$prefix}primary) !default;
$navbar-light-disabled-color: rgba($main-dark, 0.3) !default;
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' width='45' height='32' viewBox='0 0 45 32'><path fill='#{$main-color}' d='M26.88 29.888c-1.076 1.289-2.683 2.103-4.48 2.103s-3.404-0.814-4.472-2.093l-0.008-0.009-5.12-7.040-8.192-10.048-3.52-4.608c-0.646-0.848-1.036-1.922-1.036-3.087 0-2.828 2.292-5.12 5.12-5.12 0.139 0 0.277 0.006 0.413 0.016l-0.018-0.001h33.664c0.118-0.010 0.256-0.015 0.396-0.015 2.828 0 5.12 2.292 5.12 5.12 0 1.165-0.389 2.239-1.045 3.1l0.009-0.013-3.52 4.608-7.872 10.048z'/></svg>") !default;
$navbar-light-toggler-border-color: transparent !default;
//----------------------------------------------------------//
//	DROPDOWN
//----------------------------------------------------------//
$dropdown-min-width: 10rem !default;
$dropdown-padding-x: 0 !default;
$dropdown-padding-y: 1rem !default;
$dropdown-padding-y-sm: 0.65rem !default;
$dropdown-spacer: 0.75rem !default;
$dropdown-font-size: $font-size-base * 0.9375 !default;
$dropdown-color: $main-dark !default;
$dropdown-bg: var(--#{$prefix}white) !default;
$dropdown-border-radius: $border-radius !default;
$dropdown-divider-margin-y: $spacer * .5 !default;
$dropdown-box-shadow: $box-shadow-md !default;
$dropdown-link-color: $main-dark !default;
$dropdown-link-hover-color: var(--#{$prefix}primary) !default;
$dropdown-link-hover-bg: inherit !default;
$dropdown-link-active-color: var(--#{$prefix}primary) !default;
$dropdown-link-active-bg: inherit !default;
$dropdown-link-disabled-color: $gray-600 !default;
$dropdown-item-padding-y: $spacer * 0.2 !default;
$dropdown-item-padding-x: $spacer * 1.5 !default;
$dropdown-header-color: var(--#{$prefix}primary) !default;
$dropdown-header-padding-x: $dropdown-item-padding-x !default;
$dropdown-header-padding-y: $dropdown-padding-y * .5 !default;
$dropdown-dark-color: $gray-300 !default;
$dropdown-border-color: transparent !default;
$dropdown-divider-bg: $dropdown-border-color !default;
$dropdown-dark-border-color: $dropdown-border-color !default;
$dropdown-dark-divider-bg: $dropdown-divider-bg !default;
$dropdown-dark-box-shadow: null !default;
$dropdown-dark-link-color: $dropdown-dark-color !default;
$dropdown-dark-link-hover-color: var(--#{$prefix}white) !default;
$dropdown-dark-link-hover-bg: rgba(var(--#{$prefix}white-rgb), 0.15) !default;
$dropdown-dark-link-active-color: $dropdown-link-active-color !default;
$dropdown-dark-link-active-bg: $dropdown-link-active-bg !default;
$dropdown-dark-link-disabled-color: $gray-500 !default;
$dropdown-dark-header-color: $gray-500 !default;
//----------------------------------------------------------//
//	PAGINATION
//----------------------------------------------------------//
$pagination-padding-y: 0 !default;
$pagination-padding-x: 0 !default;
$pagination-padding-y-sm: 0 !default;
$pagination-padding-x-sm: 0 !default;
$pagination-padding-y-lg: 0 !default;
$pagination-padding-x-lg: 0 !default;
$pagination-size: 2.5rem !default;
$pagination-font-size: 0.65rem !default;
$pagination-icon-font-size: 0.9rem !default;
$pagination-font-weight: $font-weight-bold !default;
$pagination-size: 2.5rem !default;
$pagination-sm-size: 2rem !default;
$pagination-lg-size: 3rem !default;
$pagination-color: $main-dark !default;
$pagination-bg: var(--#{$prefix}white) !default;
$pagination-border-width: $border-width !default;
$pagination-border-radius: $border-radius !default;
$pagination-margin-start: calc(#{$pagination-border-width} * -1) !default;
$pagination-border-color: $border-light !default;
$pagination-focus-color: var(--#{$prefix}primary) !default;
$pagination-focus-bg: $pagination-bg !default;
$pagination-focus-box-shadow: $input-btn-focus-box-shadow !default;
$pagination-focus-outline: 0 !default;
$pagination-hover-color: var(--#{$prefix}primary) !default;
$pagination-hover-bg: $pagination-bg !default;
$pagination-hover-border-color: $pagination-border-color !default;
$pagination-active-color: var(--#{$prefix}primary) !default;
$pagination-active-bg: $pagination-bg !default;
$pagination-active-border-color: $pagination-border-color !default;
$pagination-disabled-color: $gray-500 !default;
$pagination-disabled-bg: $pagination-bg !default;
$pagination-disabled-border-color: $pagination-border-color !default;
$pagination-transition: $link-transition !default;
//----------------------------------------------------------//
//	CARDS
//----------------------------------------------------------//
$card-spacer-y: $spacer * 2 !default;
$card-spacer-x: $spacer * 2 !default;
$card-title-spacer-y: $spacer * .5 !default;
$card-border-width: $border-width !default;
$card-border-radius: $border-radius !default;
$card-border-color: $border-light !default;
$card-inner-border-radius: $card-border-radius !default;
$card-cap-padding-y: 0.9rem !default;
$card-cap-padding-x: $card-spacer-x !default;
$card-cap-bg: transparent !default;
$card-cap-color: null !default;
$card-title-color: $headings-color !default;
$card-height: null !default;
$card-color: null !default;
$card-bg: var(--#{$prefix}white) !default;
//----------------------------------------------------------//
//	TOOLTIP
//----------------------------------------------------------//
$tooltip-font-size: $font-size-base !default;
$tooltip-max-width: 200px !default;
$tooltip-color: var(--#{$prefix}white) !default;
$tooltip-bg: var(--#{$prefix}primary) !default;
$tooltip-border-radius: $border-radius !default;
$tooltip-opacity: 0.98 !default;
$tooltip-padding-y: $spacer * .5 !default;
$tooltip-padding-x: $spacer * .75 !default;
$tooltip-margin: 0 !default;
$tooltip-arrow-width: 0.8rem !default;
$tooltip-arrow-height: 0.4rem !default;
//----------------------------------------------------------//
//	POPOVER
//----------------------------------------------------------//
$popover-font-size: $font-size-base !default;
$popover-bg: var(--#{$prefix}white) !default;
$popover-max-width: 276px !default;
$popover-border-width: $border-width !default;
$popover-border-color: $border-light !default;
$popover-border-radius: $border-radius !default;
$popover-inner-border-radius: subtract($popover-border-radius,
  $popover-border-width) !default;
$popover-box-shadow: $box-shadow !default;
$popover-header-bg: $popover-bg !default;
$popover-header-color: $headings-color !default;
$popover-header-padding-y: $spacer !default;
$popover-header-padding-x: 1.25rem !default;
$popover-body-color: $body-color !default;
$popover-body-padding-y: $spacer !default;
$popover-body-padding-x: 1.25rem !default;
$popover-arrow-width: 1rem !default;
$popover-arrow-height: 0.5rem !default;
$popover-arrow-color: $popover-bg !default;
$popover-arrow-outer-color: fade-in($popover-border-color, 0.05) !default;
//----------------------------------------------------------//
//	BADGE
//----------------------------------------------------------//
$badge-padding-y: .4em !default;
$badge-padding-x: .7em !default;
//----------------------------------------------------------//
//	MODAL
//----------------------------------------------------------//
$modal-inner-padding: 2.5rem !default;
$modal-content-border-width: 0 !default;
$modal-backdrop-bg: rgba($gray-900, 0.9) !default;
$modal-backdrop-opacity: 1 !default;
$modal-sm: 450px !default;
$modal-md: 560px !default;
$modal-lg: 800px !default;
$modal-xl: 1140px !default;
//----------------------------------------------------------//
//	OFFCANVAS
//----------------------------------------------------------//
$offcanvas-padding-y: 1.5rem !default;
$offcanvas-padding-x: 1.5rem !default;
$offcanvas-horizontal-width: 15rem !default;
$offcanvas-vertical-height: 30vh !default;
$offcanvas-transition-duration: .3s !default;
$offcanvas-border-color: transparent !default;
$offcanvas-border-width: 0 !default;
$offcanvas-title-line-height: 1 !default;
$offcanvas-bg-color: $gray-900 !default;
$offcanvas-color: $navbar-dark-color !default;
$offcanvas-box-shadow: none !default;
$offcanvas-backdrop-bg: rgba($gray-900, 0.7) !default;
$offcanvas-backdrop-opacity: 1 !default;
//----------------------------------------------------------//
//	ALERTS
//----------------------------------------------------------//
$alert-font-weight: $font-weight-base !default;
$alert-border-width: 0 !default;
//----------------------------------------------------------//
//	BREADCRUMBS
//----------------------------------------------------------//
$breadcrumb-padding-y: 0 !default;
$breadcrumb-padding-x: 0 !default;
$breadcrumb-item-padding-x: 0.5rem !default;
$breadcrumb-margin-bottom: 1rem !default;
$breadcrumb-bg: none !default;
$breadcrumb-divider-color: rgba($gray-600, 0.35) !default;
$breadcrumb-color: $gray-600 !default;
$breadcrumb-hover-color: var(--#{$prefix}primary) !default;
$breadcrumb-active-color: $gray-600 !default;
$breadcrumb-border-radius: 0 !default;
//----------------------------------------------------------//
//	CLOSE
//----------------------------------------------------------//
$btn-close-width: 1.8rem !default;
$btn-close-height: $btn-close-width !default;
$btn-close-font-size: 1.05rem !default;
$btn-close-padding-x: 0 !default;
$btn-close-padding-y: $btn-close-padding-x !default;
$btn-close-bg: rgba($black, 0.08) !default;
$btn-close-color: $main-dark !default;
$btn-close-hover-color: $btn-close-color;
$btn-close-hover-bg: rgba($black, 0.11) !default;
$btn-close-light-bg: rgba(var(--#{$prefix}white-rgb), 0.08) !default;
$btn-close-light-color: var(--#{$prefix}white) !default;
$btn-close-light-hover-color: $btn-close-light-color;
$btn-close-light-hover-bg: rgba(var(--#{$prefix}white-rgb), 0.11) !default;
//----------------------------------------------------------//
//	SLIDER
//----------------------------------------------------------//
$hero-slider-height: 750px !default;
$hero-slider-height-mobile: 500px !default;
//----------------------------------------------------------//
//	LIGHTBOX
//----------------------------------------------------------//
$lightbox-bg: rgba($gray-900, 0.9) !default;
$lightbox-icon-color: var(--#{$prefix}white) !default;
$lightbox-icon-bg: rgba($black, 0.3) !default;
$lightbox-icon-hover-bg: rgba($black, 0.5) !default;
//----------------------------------------------------------//
//	TABLE
//----------------------------------------------------------//
$table-cell-padding-y: .9rem !default;
$table-cell-padding-x: .9rem !default;
$table-cell-padding-y-sm: .25rem !default;
$table-cell-padding-x-sm: .25rem !default;
$table-cell-vertical-align: top !default;
$table-color: $body-color !default;
$table-bg: transparent !default;
$table-accent-bg: transparent !default;
$table-th-font-weight: null !default;
$table-striped-color: $table-color !default;
$table-striped-bg-factor: .07 !default;
$table-striped-bg: rgba($border, $table-striped-bg-factor) !default;
$table-active-color: $table-color !default;
$table-active-bg-factor: .07 !default;
$table-active-bg: rgba($border, $table-active-bg-factor) !default;
$table-hover-color: $table-color !default;
$table-hover-bg-factor: .07 !default;
$table-hover-bg: rgba($border, $table-hover-bg-factor) !default;
$table-border-factor: .1 !default;
$table-border-width: $border-width !default;
$table-border-color: $table-border !default;
$table-striped-order: odd !default;
$table-group-separator-color: $table-border !default;
$table-caption-color: $text-muted !default;
//----------------------------------------------------------//
//	CODE
//----------------------------------------------------------//

$pre-font-size: 0.7rem !default;
$code-color: $body-color !default;
$code-font-size: 0.65rem !default;
$kbd-color: $code-color !default;
//----------------------------------------------------------//
//	ICONS
//----------------------------------------------------------//
$icon-close: "\ed3b" !default;
$icon-search: "\eca5" !default;
$icon-prev: "\e949" !default;
$icon-next: "\e94c" !default;
$icon-quote: "\201D" !default;
$icon-quote-top: "\201C" !default;
$icon-dot: "\2022" !default;
$icon-caret-start: "\e92f" !default;
$icon-caret-end: "\e931" !default;
$icon-caret-up: "\e932" !default;
$icon-caret-down: "\e92d" !default;
$icon-more: "\e94c" !default;
$icon-page-progress: "\e951" !default;
$icon-line: "\e904" !default;
$icon-external: "\e906" !default;
$icon-internal: "\e94c" !default;
$icon-folder: "\eb34" !default;
$icon-file: "\eaed" !default;
$icon-code: "\e952" !default;
$icon-terminal: "\e905" !default;
$icon-kbd: "\eba9" !default;
