// Use this to write your custom SCSS
.procedo-pill {
    border-radius: 50rem 50rem 50rem 0rem !important;
}

.card-kpi {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.btn.btn-shape {    
    border-radius: 50rem 50rem 50rem 0rem !important;    
}

.shadow-xl {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}

.w-80 {
    width: 80%;
}

@media (min-width: 1024px) {
    .hero-img {
        max-width: 70%;
    }

    .hero-img-aux1 {
        z-index: 8; 
        bottom: -3%; 
        right: -3%; 
        max-width: 30%; 
        height: auto
    }
}

@media (max-width: 1023px) {
    .hero-img {
        max-width: 90%;
    }

    .hero-img-aux1 {
        z-index: 8; 
        bottom: -29%;
        right: -1%;
        max-width: 30%; 
        height: auto
    }
    
}
